import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NtsAppRootComponent } from '@nts/std';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends NtsAppRootComponent {

}
