import { NgModule } from '@angular/core';
import { Routes, RouterModule, LoadChildren } from '@angular/router';
import { NotFoundComponent, MainContainerComponent, EntryPageComponent, EmptyContainerComponent, BaseRoutes } from '@nts/std';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { environment } from 'src/environments/environment';

// `export`ing these function fixed the error "Error: Cannot read property 'loadChildren' of null"
export function getEntityRoutes(title: string, entityName: string, loadChildren: LoadChildren) {
  return [
    {
      path: `manage/${entityName}`,
      data: {
        title
      },
      loadChildren,
    }
  ];
}

export function getDefaultRoutes(title: string, environment: EnvironmentConfiguration, debugMode = false) {
  return [
    debugMode ?
      {
        path: '',
        component: EntryPageComponent,
        data: {
          title: environment.appTitle
        }
      } : {}
  ];
}

const title = environment ? environment.appTitle : 'Application Title';


const routes: Routes = [
  // #region rotte con autenticazione
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      ...getEntityRoutes(
        title,
        'modulefeatures',
        () => import('./module-features/module-features.module').then(m => m.ModuleFeaturesModule)
      ),
      ...getEntityRoutes(
        title,
        'salesconfigurator',
        () => import('./sales-configurator/sales-configurator.module').then(m => m.SalesConfiguratorModule)
      ),
      ...getEntityRoutes(
        title,
        'salescharge',
        () => import('./sales-charge/sales-charge.module').then(m => m.SalesChargeModule)
      ),
      ...getEntityRoutes(
        title,
        'storeconfigurator',
        () => import('./store-configurator/store-configurator.module').then(m => m.StoreConfiguratorModule)
      ),
      ...BaseRoutes.getDefaultRoutes(
        title,
        environment,
        true
      )
    ]
  ),
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      ...getEntityRoutes(
        title,
        'subscriptionwizard',
        () => import('./subscription-wizard/subscription-wizard.module').then(m => m.SubscriptionWizardModule)
      ),
      ...BaseRoutes.getDefaultRoutes(
        title,
        environment,
        true
      )
    ],
    () => import('@nts/std').then(mod => mod.EmptyContainerWithToastComponent),
  ),
  // #endregion rotte con autenticazione

  // rotte base per la gestione degli errori
  ...BaseRoutes.getBaseErrorsRoute()
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
